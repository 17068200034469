import React, { useRef, useState, useCallback, useEffect } from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const calculateImageDimensions = (
  naturalWidth,
  naturalHeight,
  containerWidth,
  containerHeight,
  maxWidth = 1200,
  maxHeight = 800
) => {
  // Store original dimensions
  const originalWidth = naturalWidth;
  const originalHeight = naturalHeight;
  
  // Calculate display dimensions
  const aspectRatio = naturalWidth / naturalHeight;
  let targetNaturalWidth = naturalWidth;
  let targetNaturalHeight = naturalHeight;
  
  if (targetNaturalWidth > maxWidth) {
    targetNaturalWidth = maxWidth;
    targetNaturalHeight = maxWidth / aspectRatio;
  }
  if (targetNaturalHeight > maxHeight) {
    targetNaturalHeight = maxHeight;
    targetNaturalWidth = maxHeight * aspectRatio;
  }

  const containerRatio = containerWidth / containerHeight;
  let displayWidth, displayHeight;

  if (aspectRatio > containerRatio) {
    displayWidth = containerWidth;
    displayHeight = containerWidth / aspectRatio;
  } else {
    displayHeight = containerHeight;
    displayWidth = containerHeight * aspectRatio;
  }

  const scale = displayWidth / targetNaturalWidth;

  return {
    originalWidth,    // Added original dimensions
    originalHeight,   // Added original dimensions
    naturalWidth: Math.round(targetNaturalWidth),
    naturalHeight: Math.round(targetNaturalHeight),
    displayWidth: Math.round(displayWidth),
    displayHeight: Math.round(displayHeight),
    scale
  };
};

const ImageInpaintingTool = () => {
  const containerRef = useRef(null);
  const imageCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const resultCanvasRef = useRef(null);
  const [originalCanvas, setOriginalCanvas] = useState(null); // Changed to state
  
  const [image, setImage] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [brushSize, setBrushSize] = useState(20);
  const [isProcessing, setIsProcessing] = useState(false);
  const [originalFileName, setOriginalFileName] = useState('');

  const calculateContainerSize = useCallback(() => {
    if (!containerRef.current) return null;
    
    const grid = containerRef.current.querySelector('.canvas-grid');
    if (!grid) return null;

    const gridRect = grid.getBoundingClientRect();
    const columns = window.innerWidth >= 768 ? 2 : 1;
    const padding = 32;
    
    const containerWidth = (gridRect.width - padding) / columns;
    const containerHeight = window.innerHeight * 0.7;

    return { width: containerWidth, height: containerHeight };
  }, []);

  const updateCanvases = useCallback((dimensions) => {
    [imageCanvasRef, maskCanvasRef, resultCanvasRef].forEach(ref => {
      const canvas = ref.current;
      if (!canvas) return;

      canvas.width = dimensions.naturalWidth;
      canvas.height = dimensions.naturalHeight;
      canvas.style.width = `${dimensions.displayWidth}px`;
      canvas.style.height = `${dimensions.displayHeight}px`;

      const ctx = canvas.getContext('2d');
      if (ref === maskCanvasRef) {
        ctx.fillStyle = '#FFFFFF';
        ctx.strokeStyle = '#FFFFFF';
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
      }
    });

    // Create and set up original-sized canvas
    const newOriginalCanvas = document.createElement('canvas');
    newOriginalCanvas.width = dimensions.originalWidth;
    newOriginalCanvas.height = dimensions.originalHeight;
    setOriginalCanvas(newOriginalCanvas);
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (!image) return;
      
      const container = calculateContainerSize();
      if (!container) return;

      const newDimensions = calculateImageDimensions(
        image.naturalWidth,
        image.naturalHeight,
        container.width,
        container.height
      );

      setDimensions(newDimensions);
      updateCanvases(newDimensions);

      if (imageCanvasRef.current) {
        const ctx = imageCanvasRef.current.getContext('2d');
        ctx.drawImage(image, 0, 0, newDimensions.naturalWidth, newDimensions.naturalHeight);
      }
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [image, calculateContainerSize, updateCanvases]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setOriginalFileName(file.name.replace(/\.[^/.]+$/, ''));
    
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const container = calculateContainerSize();
        if (!container) return;

        const newDimensions = calculateImageDimensions(
          img.naturalWidth,
          img.naturalHeight,
          container.width,
          container.height
        );

        setDimensions(newDimensions);
        setImage(img);
        
        // Draw image on display canvas
        const ctx = imageCanvasRef.current.getContext('2d');
        ctx.drawImage(img, 0, 0, newDimensions.naturalWidth, newDimensions.naturalHeight);
        
        // Clear mask and result canvases
        [maskCanvasRef, resultCanvasRef].forEach(ref => {
          const ctx = ref.current.getContext('2d');
          ctx.clearRect(0, 0, newDimensions.naturalWidth, newDimensions.naturalHeight);
        });

        // Create new original canvas and draw the image
        const newOriginalCanvas = document.createElement('canvas');
        newOriginalCanvas.width = newDimensions.originalWidth;
        newOriginalCanvas.height = newDimensions.originalHeight;
        const originalCtx = newOriginalCanvas.getContext('2d');
        originalCtx.drawImage(img, 0, 0, newDimensions.originalWidth, newDimensions.originalHeight);
        setOriginalCanvas(newOriginalCanvas);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const startDrawing = (e) => {
    if (!image || !dimensions) return;
    setIsDrawing(true);
    draw(e);
  };

  const draw = (e) => {
    if (!isDrawing || !image || !dimensions) return;

    const canvas = maskCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d');

    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);

    const scaledBrushSize = brushSize / dimensions.scale;
    
    ctx.lineWidth = scaledBrushSize;
    ctx.beginPath();
    ctx.arc(x, y, scaledBrushSize / 2, 0, Math.PI * 2);
    ctx.fill();

    updateMaskPreview();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const clearMask = () => {
    if (!dimensions) return;
    const ctx = maskCanvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, dimensions.naturalWidth, dimensions.naturalHeight);
    updateMaskPreview();
  };

  const updateMaskPreview = () => {
    if (!dimensions || !originalCanvas) return;
    
    // Update display preview
    const resultCtx = resultCanvasRef.current.getContext('2d');
    resultCtx.fillStyle = '#000000';
    resultCtx.fillRect(0, 0, dimensions.naturalWidth, dimensions.naturalHeight);
    resultCtx.globalCompositeOperation = 'source-over';
    resultCtx.drawImage(maskCanvasRef.current, 0, 0);

    // Update original-sized mask
    const originalCtx = originalCanvas.getContext('2d');
    originalCtx.fillStyle = '#000000';
    originalCtx.fillRect(0, 0, dimensions.originalWidth, dimensions.originalHeight);
    originalCtx.globalCompositeOperation = 'source-over';
    
    // Scale the mask to original dimensions
    originalCtx.save();
    originalCtx.scale(
      dimensions.originalWidth / dimensions.naturalWidth,
      dimensions.originalHeight / dimensions.naturalHeight
    );
    originalCtx.drawImage(maskCanvasRef.current, 0, 0);
    originalCtx.restore();
  };

  const processInpainting = async () => {
    if (!dimensions) return;
    setIsProcessing(true);

    try {
      updateMaskPreview();
    } catch (error) {
      console.error('Inpainting failed:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadResult = () => {
    if (!dimensions || !originalCanvas) return;
    const link = document.createElement('a');
    link.download = `${originalFileName}_mask.png`;
    link.href = originalCanvas.toDataURL();
    link.click();
  };

  return (
    <Layout>
      <Seo 
        title="Image Inpainting Tool" 
        description="Free online tool to create image masks by painting. Generate black and white masks for image inpainting, photo editing, or AI tools. Simple brush-based interface with adjustable size and instant preview."
      />
      <div className="max-w-4xl mx-auto p-4" ref={containerRef}>
        <h1 className="text-3xl font-bold mb-4">Image Inpainting Tool</h1>
        <p className="mb-4 text-gray-600">
          Upload an image and paint over the areas you want to remove or fix.
        </p>

        <div className="flex flex-col space-y-4">
          <div className="flex flex-wrap gap-4 items-center">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="flex-grow text-sm text-gray-500 
                       file:mr-4 file:py-2 file:px-4 
                       file:rounded-lg file:border-0 
                       file:text-sm file:font-medium
                       file:bg-blue-50 file:text-blue-700 
                       hover:file:bg-blue-100
                       cursor-pointer"
            />
            <div className="flex gap-2">
              <button 
                onClick={clearMask}
                className="px-4 py-2 text-sm font-medium text-gray-700 
                         bg-white border border-gray-300 rounded-lg
                         hover:bg-gray-50 focus:outline-none focus:ring-2 
                         focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear Mask
              </button>
              <button 
                onClick={downloadResult}
                disabled={!image}
                className="px-4 py-2 text-sm font-medium text-white 
                         bg-green-600 rounded-lg hover:bg-green-700 
                         focus:outline-none focus:ring-2 focus:ring-offset-2 
                         focus:ring-green-500 disabled:opacity-50"
              >
                Download
              </button>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <span className="text-sm font-medium min-w-[100px]">
              Brush Size: {brushSize}px
            </span>
            <input
              type="range"
              value={brushSize}
              onChange={(e) => setBrushSize(parseInt(e.target.value))}
              min="1"
              max="50"
              className="w-48 h-2 bg-gray-200 rounded-lg appearance-none 
                       cursor-pointer accent-blue-600"
            />
          </div>

          <div className="canvas-grid grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="canvas-container flex justify-center items-center border border-gray-200 rounded-lg overflow-hidden bg-gray-50 p-4">
              <div className="relative">
                {!dimensions ? (
                  <div className="w-full h-64 flex items-center justify-center text-gray-400">
                    Upload an image to start
                  </div>
                ) : (
                  <div 
                    style={{
                      width: dimensions.displayWidth,
                      height: dimensions.displayHeight
                    }}
                  >
                    <canvas
                      ref={imageCanvasRef}
                      className="absolute top-0 left-0"
                    />
                    <canvas
                      ref={maskCanvasRef}
                      className="absolute top-0 left-0 cursor-crosshair"
                      onMouseDown={startDrawing}
                      onMouseMove={draw}
                      onMouseUp={stopDrawing}
                      onMouseOut={stopDrawing}
                    />
                  </div>
                )}
              </div>
            </div>
            
            <div className="canvas-container flex justify-center items-center border border-gray-200 rounded-lg overflow-hidden bg-gray-50 p-4">
              <div className="relative">
                {!dimensions ? (
                  <div className="w-full h-64 flex items-center justify-center text-gray-400">
                    Result will appear here
                  </div>
                ) : (
                  <div 
                    style={{
                      width: dimensions.displayWidth,
                      height: dimensions.displayHeight
                    }}
                  >
                    <canvas
                      ref={resultCanvasRef}
                      className="absolute top-0 left-0"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImageInpaintingTool;